"use client"
import { GetPageQuery } from "apollo"
import { BlockRenderer } from "ui"
import { Culture, findProperty } from "utils"
import BonitaIcons from "./Homepage/BonitaIcons"
import BonitaMap from "./Homepage/BonitaMap"
import CallToAction from "./Homepage/CallToAction"
import HomeHeader from "./Homepage/HomeHeader"
import ImageTextBlock from "./Homepage/ImageTextBlock"
import PowerNumbers from "./Homepage/PowerNumbers"

type Props = {
  data: GetPageQuery
  culture: Culture
  locale: string
}

function TierraHomepage({ data, culture, locale }: Props) {
  const components = {
    tierraBonitaHomeHeader: HomeHeader,
    tierraBonitaImageAndTextBlock: ImageTextBlock,
    tierraBonitaIcons: BonitaIcons,
    tierraBonitaPowerNumbers: PowerNumbers,
    tierraBonitaMap: BonitaMap,
    tierraBonitaCallToAction: CallToAction,
  }
  const blocks = findProperty(data.page, "blocks")?.blocks || []
  return (
    <>
      {blocks?.map(
        // TO DO : HOW TO FIND BLOCK TYPE IN GENERATED GRAPHQL TYPES
        (block: any, index: number) => {
          let additionalProps = {}
          if (block.blockType === "tierraBonitaImageAndTextBlock" && index === 1) {
            additionalProps = {
              noBottomPadding: true,
            }
          }
          return (
            <BlockRenderer
              key={index}
              blockType={block.blockType}
              blockData={block}
              components={components}
              culture={culture}
              locale={locale}
              props={additionalProps}
            />
          )
        },
      )}
    </>
  )
}

export default TierraHomepage
